import { render, staticRenderFns } from "./ApexChartHistorical.vue?vue&type=template&id=6ab5c243"
import script from "./ApexChartHistorical.vue?vue&type=script&lang=js"
export * from "./ApexChartHistorical.vue?vue&type=script&lang=js"
import style0 from "./ApexChartHistorical.vue?vue&type=style&index=0&id=6ab5c243&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports